import {withAuthenticationRequired} from '@auth0/auth0-react';
import {FC, lazy, Suspense, useEffect} from 'react';
import {useQuery} from 'react-query';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import {WithChildren} from '../../_metronic/helpers';
import {usePageData} from '../../_metronic/layout/core';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import useBearerToken from '../../modules/auth/hooks/useBearerToken';
import useCustomTranslation from '../../modules/translation/hook/use-custom-translation';
import {PermissionProvider, usePermission} from '../modules/auth/core/Permissions';
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
import {getLoanSettings} from '../pages/loans/loans.services';
import {MenuTestPage} from '../pages/MenuTestPage';
import {Unauthorized} from '../pages/Unauthorized';
import DocumentHeader from './DocumentHeader';
import CustomModal from '../../components/CustomModal';
// Load the user and its permissions
const ClientsPage = lazy(() => import('../pages/clients/ClientsPage'));
const CashAccountPage = lazy(() => import('../pages/cashAccounts/CashAccountPage'));
const WalletsPage = lazy(() => import('../pages/wallets/WalletsPage'));
const ExpensesPage = lazy(() => import('../pages/expenses/ExpensesPage'));
const NotesPage = lazy(() => import('../pages/notes/NotesPage'));
const UsersPage = lazy(() => import('../pages/users/UsersPage'));

// TODO - remove the following pages since they are just references
const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
const UserManagementPage = lazy(() => import('../modules/apps/user-management/UsersPage'));
const LoansPage = lazy(() => import('../pages/loans/LoanPage'));
const PaymentPage = lazy(() => import('../pages/payments/PaymentsPage'));
const SegmentsPage = lazy(() => import('../pages/segments/SegmentsPage'));
const TemplatesPage = lazy(() => import('../pages/templates/TemplatesPage'));

// Informs
const LoanInformPage = lazy(() => import('../pages/informs/components/LoanInformPage'));
const PaymentInformPage = lazy(() => import('../pages/informs/components/PaymentInformPage'));
const CashAccountsInformPage = lazy(
  () => import('../pages/informs/components/CashAccountsInformPage')
);
const WalletsInformPage = lazy(() => import('../pages/informs/components/WalletsInformPage'));
const SegmentInformPage = lazy(() => import('../pages/informs/components/SegmentInformPage'));
const LoanStatementPage = lazy(() => import('../pages/informs/components/LoanStatementPage'));
const ClientsInformPage = lazy(() => import('../pages/informs/components/ClientsInformPage'));

// Charts
const DashboardPage = lazy(() => import('../pages/charts/DashboardPage'));

const PrivateRoutes = () => {
  // Set Bearer token MiddleWare
  useBearerToken();
  const {hasPermission, loading, error, getInitialRoute, hasAnyPermission} = usePermission();
  const location = useLocation();
  const {setExchangeRate, setSettingId} = usePageData();

  useQuery('settings', getLoanSettings, {
    cacheTime: 0,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: !loading && !error,
    onSuccess: (data) => {
      setSettingId(data?.id || '');
      setExchangeRate(data?.exchangeRate || 0);
    },
  });

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Routes location={location}>
      <Route element={<MasterLayout />}>
        {error ? null : (
          <>
            {/* Redirect to Dashboard after success login/registartion */}
            <Route path='auth/*' element={<Navigate to='/dashboard' />} />
            {/* Pages */}
            {hasPermission('read:user-section') && (
              <Route
                path='usuarios/*'
                element={
                  <SuspensedView>
                    <UsersPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:client-section') && (
              <Route
                path='clientes/*'
                element={
                  <SuspensedView>
                    <ClientsPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:segment-section') && (
              <Route
                path='rutas/*'
                element={
                  <SuspensedView>
                    <SegmentsPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:template-section') && (
              <Route
                path='plantillas/*'
                element={
                  <SuspensedView>
                    <TemplatesPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:cash-section') && (
              <Route
                path='cajas/*'
                element={
                  <SuspensedView>
                    <CashAccountPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:wallet-section') && (
              <Route
                path='carteras/*'
                element={
                  <SuspensedView>
                    <WalletsPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:expense-section') && (
              <Route
                path='gastos/*'
                element={
                  <SuspensedView>
                    <ExpensesPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:payment-section') && (
              <Route
                path='pagos/*'
                element={
                  <SuspensedView>
                    <PaymentPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:loan-section') && (
              <Route
                path='prestamos/*'
                element={
                  <SuspensedView>
                    <LoansPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:note-credit-debit-section') && (
              <Route
                path='notas/*'
                element={
                  <SuspensedView>
                    <NotesPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:report-loan-section') && (
              <Route
                path='informe-prestamos/*'
                element={
                  <SuspensedView>
                    <LoanInformPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:report-payment-section') && (
              <Route
                path='informe-pagos/*'
                element={
                  <SuspensedView>
                    <PaymentInformPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:report-cash-section') && (
              <Route
                path='informe-cajas/*'
                element={
                  <SuspensedView>
                    <CashAccountsInformPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:report-wallet-section') && (
              <Route
                path='informe-carteras/*'
                element={
                  <SuspensedView>
                    <WalletsInformPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:report-segment-section') && (
              <Route
                path='informe-rutas/*'
                element={
                  <SuspensedView>
                    <SegmentInformPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:report-client-section') && (
              <Route
                path='informe-clientes/*'
                element={
                  <SuspensedView>
                    <ClientsInformPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:report-statement-section') && (
              <Route
                path='informe-estado-cuenta/*'
                element={
                  <SuspensedView>
                    <LoanStatementPage />
                  </SuspensedView>
                }
              />
            )}
            {hasPermission('read:stadistic-section')}
            <Route
              path='dashboard/*'
              element={
                <SuspensedView>
                  <DashboardPage />
                </SuspensedView>
              }
            />
            {/* TODO remove */}
            <Route
              path='builder'
              element={
                <SuspensedView>
                  <BuilderPageWrapper />
                </SuspensedView>
              }
            />
            <Route path='menu-test' element={<MenuTestPage />} />
            <Route
              path='crafted/pages/profile/*'
              element={
                <SuspensedView>
                  <ProfilePage />
                </SuspensedView>
              }
            />
            <Route
              path='crafted/pages/wizards/*'
              element={
                <SuspensedView>
                  <WizardsPage />
                </SuspensedView>
              }
            />
            <Route
              path='crafted/widgets/*'
              element={
                <SuspensedView>
                  <WidgetsPage />
                </SuspensedView>
              }
            />
            <Route
              path='crafted/account/*'
              element={
                <SuspensedView>
                  <AccountPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/chat/*'
              element={
                <SuspensedView>
                  <ChatPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/user-management/*'
              element={
                <SuspensedView>
                  <UserManagementPage />
                </SuspensedView>
              }
            />
            {/* TODO remove ^ */}
            {/* Page Not Found */}
          </>
        )}
        <Route index element={<Navigate to={getInitialRoute()} />} />
        <Route path='solicitar-acceso' element={<Unauthorized />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const PrivateRouteWrapper = withAuthenticationRequired(
  () => {
    return (
      <PermissionProvider>
        <PrivateRoutes />
        <CustomModal />
      </PermissionProvider>
    );
  },
  {
    onRedirecting: () => <LoadingScreen />,
  }
);

const LoadingScreen = () => {
  const {getTranslation} = useCustomTranslation();
  useEffect(() => {
    // Add the class to the body when the component mounts
    document.body.classList.add('page-loading');

    // Return a function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove('page-loading');
    };
  }, []);

  return (
    <div className='splash-screen d-flex'>
      <img
        src={`${process.env.PUBLIC_URL}/media/logos/default-dark.svg`}
        className='dark-logo'
        alt='Metronic dark logo'
      />
      <img
        src={`${process.env.PUBLIC_URL}/media/logos/default.svg`}
        className='light-logo'
        alt='Metronic light logo'
      />
      <span>{getTranslation('GENERAL.LOADING')}</span>
    </div>
  );
};

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {PrivateRouteWrapper as PrivateRoutes};
