import React from 'react';
import { CustomControlError } from './CustomControlError';
import styled from 'styled-components';
import clsx from 'clsx';

export type CustomTextAreaProps = {
  className?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  error?: string;
  name: string;
  rows?: number;
  style?: React.CSSProperties;
};

const CustomTextAreaContainer = styled.div`
  label {
    &:after {
      background: none !important;
    }
  }
  textarea:not(:empty) + label {
    display: none;
  }
`;

export const CustomTextArea = ({
  className = 'form-control-solid',
  onBlur,
  value,
  onChange,
  placeholder,
  error,
  ...props
}: CustomTextAreaProps) => {
  return (
    <CustomTextAreaContainer className='form-floating'>
      <textarea
        className={clsx(`form-control pt-5 ${className} ${error ? 'border-danger' : ''}`, {
          'min-h-100px': !className || !className.includes('h-')
        })}
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <label htmlFor={props.name}>{placeholder}</label>
      {!!error && <CustomControlError name={props.name} error={error} />}
    </CustomTextAreaContainer>
  );
};
